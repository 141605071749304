@use "../settings/mixins" as m;
@use "../settings/vars" as v;

// kv
.kv {
  position: relative;
  overflow: hidden;
  margin-top: 130px;
  @include m.mq(max767) {
    margin-top: 70px;
    height: calc(100vh - 70px);
  }
}
.kv-main {
  @include m.mq(max767) {
    margin-top: 60px;
    height: calc(100% - 60px);
  }
  width: calc(11/12 * 100vw);
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    @include m.mq(max767) {
      height: 100%;
    }
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: v.$black;
    transition: .8s cubic-bezier(.83, 0, .17, 1);
  }
  &.active {
    &::after {
      right: -100%;
    }
  }
}

// topics(news)
.top-news {
  padding: 80px 0 0;
  @include m.mq(max767) {
    padding: 60px 0 0;
  }
}
.top-news-inner {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  @include m.mq(max767) {
    flex-direction: column-reverse;
    gap: 50px;
  }
}
.top-news-topics {
  width: 58%;
  @include m.mq(max767) {
    width: 100%;
  }
}
.top-news-pickup {
  width: 35%;
  @include m.mq(max767) {
    width: 100%;
  }
}
.top-news-pickup-text {
  margin-bottom: 20px;
  a {
    text-decoration: underline;
  }
}
.top-news-pickup-img {
  display: block;
}
.top-news-pickup-text a,
.top-news-pickup-img img {
  transition: 0.3s;
  @include m.hover() {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.news-item {
  position: relative;
  overflow: hidden;
  border-top: 1px solid v.$gray;
  &:last-child {
    border-bottom: 1px solid v.$gray;
  }
  a {
    display: flex;
    gap: 20px;
    padding: 16px;
    @include m.mq(max767) {
      flex-direction: column;
      gap: 0px;
    }
    .date {
      flex-shrink: 0;
      @include m.ttc;
      font-weight: 500;
      letter-spacing: 0.05em;
      @include m.mq(max767) {
        font-size: 14px;
      }
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: v.$black02;
    transition: 0.2s ease-out;
  }
  @include m.hover() {
    &::after {
      left: 0;
      transition: 0.2s ease-out;
    }
  }
}

// top-about
.top-about {
  padding: 140px 0;
  @include m.mq(max767) {
    padding: 90px 0 180px;
  }
}
.top-about-main {
  position: relative;
}
.top-about-text {
  margin-top: 20px;
  font-size: 18px;
  line-height: 2;
  @include m.mq(max767) {
    width: 80%;
    margin-top: 15px;
    font-size: 15px;
  }
}
.top-about-img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 70%;
  margin-left: auto;
  @include m.mq(max767) {
    width: 80%;
    bottom: -100px;
  }
  img {
    height: 100%;
    aspect-ratio: 3 / 2;
  }
}

// top-race
.top-race {
  position: relative;
}
.top-race-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: .3;
  @include m.ttc;
  font-size: 15vw;
  font-weight: 700;
  writing-mode: vertical-lr;
  line-height: 0.4;
}
.top-race-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.top-race-block {
  position: relative;
  overflow: hidden;
  width: calc((100% - 15px)/2);
  @include m.mq(max767) {
    width: 100%;
  }
  &::after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: 2;
    transition: opacity 1s ease;
  }
  @include m.hover() {
    &::after {
      opacity: 0;
    }
    .top-race-block-title {
      span::before {
        transform: translate(0)
      }
    }
    .top-race-block-img {
      transform: scale(1.05);
      transition: 0.8s ease-out;
    }
  }
  &.ecstore {
    position: relative;
    width: 100%;
    &::after {
      content: none;
    }
    .top-race-block-title {
      top: auto;
      bottom: 24px;
      left: 40px;
      @include m.mq(max767) {
        bottom: 16px;
        left: 24px;
        font-size: 30px;
      }
    }
    .top-race-block-img {
      aspect-ratio: 4 / 1;
      @include m.mq(max767) {
        aspect-ratio: 5 / 2;
      }
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 40px;
      bottom: 24px;
      z-index: 3;
      width: 40px;
      height: 40px;
      background-image: url(../../common/images/arrow_link.svg);
      background-position: center 0;
      background-size: contain;
      background-repeat: no-repeat;
      @include m.mq(max767) {
        width: 25px;
        height: 25px;
      }
    }
  }
}
.top-race-block-title {
  position: absolute;
  overflow: hidden;
  top: 80px;
  left: 80px;
  z-index: 3;
  @include m.ttc;
  font-size: 5vw;
  font-weight: 700;
  line-height: 1;
  @include m.mq(max1023) {
    top: 50px;
    left: 50px;
  }
  @include m.mq(max767) {
    top: 30px;
    left: 20px;
    font-size: 48px;
  }
  span {
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 80%;
      width: 100%;
      transform: translate(-100%);
      z-index: -1;
      background: v.$black;
      transition: transform .3s ease-in-out;
    }
  }
}
.top-race-block-btn {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 3;
  @include m.ttc;
  font-weight: 500;
  font-size: 1vw;
  @include m.mq(max1023) {
    right: 20px;
    bottom: 20px;
  }
  @include m.mq(max767) {
    font-size: 20px;
  }
  .text {
    margin-top: 3px;
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: v.$white;
    &::after {
      content: '';
      display: block;
      width: 17px;
      height: 14px;
      background-image: url(../../common/images/arrow_right_black.svg);
      background-position: center 0;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
.top-race-block-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 15 / 16;
  object-fit: cover;
  transition: 0.8s ease-out;
}

// top-movie
.top-movie {
  padding: 140px 0;
  @include m.mq(max767) {
    padding: 60px 0 100px;
  }
}
.top-movie-list {
  position: relative;
}
.top-movie-item {
  margin: 0 6vw;
  iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
  }
}

// top-logo
.top-logo {
  padding: 80px 0;
  background-color: v.$white;
  @include m.mq(max767) {
    padding: 40px 0;
  }
  &,a {
    color: v.$black;
  }
}
.logo-main {
  display: block;
  width: 250px;
  margin: 0 auto 36px;
  transition: 0.3s;
  @include m.hover() {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.logo-list-title {
  margin: 40px 0 8px;
  font-weight: 700;
  text-align: center;
  @include m.mq(max767) {
    margin: 30px 0 16px;
  } 
}
.logo-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  @include m.mq(max767) {
    justify-content: flex-start;
    gap: 0px;
    padding: 0;
    font-size: 14px;
  }
  li {
    @include m.mq(max767) {
      width: 50%;
    }
    a {
      position: relative;
      @include m.mq(max767) {
        display: flex;
        justify-content: center;
      }
      .application {
        display: block;
        position: absolute;
        width: 100%;
        font-size: 0.75em;
        text-align: center;
        @include m.mq(max767) {
          bottom: -2px;
        }
      }
      transition: 0.3s;
      @include m.hover() {
        opacity: 0.7;
        transition: 0.3s;
      }
      img {
        @include m.mq(max767) {
          max-width: 80%;
          margin-bottom: 16px;
          object-fit: contain;
        }
      }
      .text {
        @include m.mq(max767) {
          display: block;
          max-width: 80%;
          margin: 8px 0 16px;
        }
      }
    }
  }
}
