@use "../settings/mixins" as m;
@use "../settings/vars" as v;

// btn
.more-btn {
  display: inline-flex;
  align-items: center;
  gap: 28px;
  margin-top: 40px;
  padding-bottom: 4px;
  border-bottom: 1px solid v.$white;
  @include m.ttc;
  font-size: 20px;
  font-weight: 700;
  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 17px;
    background-image: url(../../common/images/arrow_right_white.svg);
    background-position: center 0;
    background-size: contain;
    background-repeat: no-repeat;
  }
  transition: 0.3s;
  &:hover {
    gap: 78px;
    padding-left: 4px;
    transition: 0.3s cubic-bezier(.83, 0, .17, 1);
  }
  &.margin-top {
    @include m.mq(max767) {
      margin-top: 15px;
    }
  }
}

// section-title
.section-title {
  margin-bottom: 30px;
	@include m.avenir;
  font-size: 40px;
  font-size: 4vw;
	letter-spacing: 0.05em;
  line-height: 1;
  @include m.mq(max767) {
    font-size: 32px;
  }
  &.sp-small {
    @include m.mq(max767) {
      margin-bottom: 0;
      font-size: 20px;
    }
  }
}
.top-movie {
  .section-title {
    @include m.mq(max767) {
      font-size: 24px
    }
  }
}

// accr-title
.accr-title {
  @include m.avant;
  line-height: 1;
  font-size: 10vw;
  line-height: 0.8;
  @include m.mq(max767) {
    font-size: 72px;
  }
  @include m.mq(max374) {
    font-size: calc((72 / 374)*100vw);
  }
  span {
    display: inline-block;
  }
}

.kv {
  .accr-title {
    position: absolute;
    top: -6px;
    right: 30px;
    z-index: 2;
    text-align: right;
    @include m.mq(max767) {
      top: -2px;
      right: 20px;
    }
  }
}
.top-about-content {
  .accr-title {
    margin-top: 30px;
    font-size: 8.5vw;
    text-align: left;
    @include m.mq(max767) {
      margin-top: 15px;
      font-size: 60px;
    }
  }
}
