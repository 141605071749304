@charset "UTF-8";
@font-face {
  font-family: "ITC Avant Garde Gothic";
  src: url("../fonts/AvantGarde-Bold.woff") format("woff");
}
@font-face {
  font-family: "TTCommons";
  src: url("../fonts/TTCommons-Medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "TTCommons";
  src: url("../fonts/TTCommons-Bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "AvenirLTStd-Black";
  src: url("../fonts/AvenirLTStd-Black.woff") format("woff");
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: none;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

* {
  box-sizing: border-box;
  word-break: break-all;
}

html {
  -webkit-text-size-adjust: none;
}

body {
  font-family: Zen Kaku Gothic New, sans-serif;
  background-color: #111;
  color: #fff;
  line-height: 1.5;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #fff;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  vertical-align: bottom;
}

ol {
  padding-left: 20px;
  list-style-type: decimal;
}

strong {
  font-weight: bold;
}

sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

/* IE表示用のCSS　*/
body.ie {
  font-family: YakuHanJP_Narrow, Meiryo, sans-serif;
}

.more-btn {
  display: inline-flex;
  align-items: center;
  gap: 28px;
  margin-top: 40px;
  padding-bottom: 4px;
  border-bottom: 1px solid #fff;
  font-family: "TTCommons", sans-serif;
  font-size: 20px;
  font-weight: 700;
  transition: 0.3s;
}
.more-btn::after {
  content: "";
  display: block;
  width: 20px;
  height: 17px;
  background-image: url(../../common/images/arrow_right_white.svg);
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
}
.more-btn:hover {
  gap: 78px;
  padding-left: 4px;
  transition: 0.3s cubic-bezier(0.83, 0, 0.17, 1);
}
@media screen and (max-width: 767px) {
  .more-btn.margin-top {
    margin-top: 15px;
  }
}

.section-title {
  margin-bottom: 30px;
  font-family: "AvenirLTStd-Black", sans-serif;
  font-size: 40px;
  font-size: 4vw;
  letter-spacing: 0.05em;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .section-title {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .section-title.sp-small {
    margin-bottom: 0;
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .top-movie .section-title {
    font-size: 24px;
  }
}

.accr-title {
  font-family: "ITC Avant Garde Gothic", sans-serif;
  line-height: 1;
  font-size: 10vw;
  line-height: 0.8;
}
@media screen and (max-width: 767px) {
  .accr-title {
    font-size: 72px;
  }
}
@media screen and (max-width: 374px) {
  .accr-title {
    font-size: 19.2513368984vw;
  }
}
.accr-title span {
  display: inline-block;
}

.kv .accr-title {
  position: absolute;
  top: -6px;
  right: 30px;
  z-index: 2;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .kv .accr-title {
    top: -2px;
    right: 20px;
  }
}

.top-about-content .accr-title {
  margin-top: 30px;
  font-size: 8.5vw;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .top-about-content .accr-title {
    margin-top: 15px;
    font-size: 60px;
  }
}

.inner-m,
.inner-l {
  margin: 0 auto;
  box-sizing: content-box;
}

.inner-m {
  max-width: 1024px;
}

.inner-l {
  max-width: 1600px;
  width: 90%;
}

.center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .br-pc {
    display: none;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 40px;
}
@media screen and (max-width: 767px) {
  .header {
    padding: 12px;
  }
}

.header-inner {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.header-logo {
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .header-logo {
    width: 80px;
    height: 36px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .header-logo:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.header-logo img {
  height: 100%;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right-icons {
  display: flex;
  gap: 24px;
}
.header-right-icons a {
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .header-right-icons a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.header-right-icons a img {
  width: auto;
  height: 20px;
}

.header-menu-btn {
  position: relative;
  width: 40px;
  height: 20px;
  margin-left: 40px;
  cursor: pointer;
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .header-menu-btn:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.header-menu-btn span {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
}
.header-menu-btn span:nth-child(1), .header-menu-btn span:nth-child(3) {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.header-menu-btn span:nth-child(1) {
  top: 0;
  animation-name: menuLineTopClose;
}
.header-menu-btn span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  transition: 0.3s cubic-bezier(0.83, 0, 0.17, 1);
}
.header-menu-btn span:nth-child(3) {
  bottom: 0;
  animation-name: menuLineBottomClose;
}
.header-menu-btn.active span:nth-child(1) {
  animation-name: menuLineTop;
}
.header-menu-btn.active span:nth-child(2) {
  opacity: 0;
  transition: 0.3s cubic-bezier(0.83, 0, 0.17, 1);
}
.header-menu-btn.active span:nth-child(3) {
  animation-name: menuLineBottom;
}

.header-nav {
  overflow: scroll;
  display: grid;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  padding: 130px 10%;
  background-color: #111;
  transform: translateY(-100%);
  transition: 0.5s cubic-bezier(0.83, 0, 0.17, 1);
}
@media screen and (max-width: 767px) {
  .header-nav {
    align-items: flex-start;
    padding: 70px 10%;
  }
}
.header-nav.show {
  transform: translateY(0);
  transition: 0.5s cubic-bezier(0.83, 0, 0.17, 1);
}
.header-nav::-webkit-scrollbar {
  display: none;
}

.header-nav-title {
  margin-bottom: 24px;
  font-family: "ITC Avant Garde Gothic", sans-serif;
  font-size: 36px;
}
@media screen and (max-width: 767px) {
  .header-nav-title {
    margin-bottom: 12px;
    font-size: 24px;
  }
}

.header-nav-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 24px 0;
  border-top: 1px solid #666;
}
@media screen and (max-width: 767px) {
  .header-nav-list {
    padding: 12px 0;
  }
}
.header-nav-list.main .header-nav-item {
  width: calc((100% - 32px) / 3);
}
@media screen and (max-width: 767px) {
  .header-nav-list.main .header-nav-item {
    width: 100%;
  }
}
.header-nav-list.sub .header-nav-item {
  width: 100%;
}

.header-nav-item a {
  display: inline-flex;
  flex-direction: column;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .header-nav-item a {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}
.header-nav-item a .en {
  font-family: "ITC Avant Garde Gothic", sans-serif;
  font-size: 24px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .header-nav-item a .en {
    font-size: 16px;
  }
}
.header-nav-item a .en.external-link {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 767px) {
  .header-nav-item a .en.external-link {
    gap: 7px;
  }
}
.header-nav-item a .en.external-link::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../../common/images/icon_external-link.svg);
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .header-nav-item a .en.external-link::after {
    width: 15px;
    height: 15px;
  }
}
.header-nav-item a .jp {
  color: #999;
}
@media screen and (max-width: 767px) {
  .header-nav-item a .jp {
    font-size: 12px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .header-nav-item a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.test {
  opacity: 0;
}

.footer {
  padding: 50px 0;
}
@media screen and (max-width: 767px) {
  .footer {
    padding: 40px 0;
  }
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
}

.footer-sns {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  font-family: "TTCommons", sans-serif;
  font-size: 20px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .footer-sns {
    flex-direction: column;
    gap: 10px;
  }
}

.footer-sns-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
@media screen and (max-width: 767px) {
  .footer-sns-list {
    gap: 0 24px;
  }
}
.footer-sns-list a {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: 0.3s;
}
.footer-sns-list a::after {
  content: "";
  display: block;
  width: 10px;
  height: 12px;
  background-image: url(../../common/images/arrow_link.svg);
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (hover: hover) and (pointer: fine) {
  .footer-sns-list a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.footer-copyright {
  font-family: "TTCommons", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

.section-padding {
  padding: 140px 0;
}

.kv {
  position: relative;
  overflow: hidden;
  margin-top: 130px;
}
@media screen and (max-width: 767px) {
  .kv {
    margin-top: 70px;
    height: calc(100vh - 70px);
  }
}

.kv-main {
  width: 91.6666666667vw;
}
@media screen and (max-width: 767px) {
  .kv-main {
    margin-top: 60px;
    height: calc(100% - 60px);
  }
}
.kv-main img {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .kv-main img {
    height: 100%;
  }
}
.kv-main::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #111;
  transition: 0.8s cubic-bezier(0.83, 0, 0.17, 1);
}
.kv-main.active::after {
  right: -100%;
}

.top-news {
  padding: 80px 0 0;
}
@media screen and (max-width: 767px) {
  .top-news {
    padding: 60px 0 0;
  }
}

.top-news-inner {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media screen and (max-width: 767px) {
  .top-news-inner {
    flex-direction: column-reverse;
    gap: 50px;
  }
}

.top-news-topics {
  width: 58%;
}
@media screen and (max-width: 767px) {
  .top-news-topics {
    width: 100%;
  }
}

.top-news-pickup {
  width: 35%;
}
@media screen and (max-width: 767px) {
  .top-news-pickup {
    width: 100%;
  }
}

.top-news-pickup-text {
  margin-bottom: 20px;
}
.top-news-pickup-text a {
  text-decoration: underline;
}

.top-news-pickup-img {
  display: block;
}

.top-news-pickup-text a,
.top-news-pickup-img img {
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .top-news-pickup-text a:hover,
.top-news-pickup-img img:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.news-item {
  position: relative;
  overflow: hidden;
  border-top: 1px solid #707070;
}
.news-item:last-child {
  border-bottom: 1px solid #707070;
}
.news-item a {
  display: flex;
  gap: 20px;
  padding: 16px;
}
@media screen and (max-width: 767px) {
  .news-item a {
    flex-direction: column;
    gap: 0px;
  }
}
.news-item a .date {
  flex-shrink: 0;
  font-family: "TTCommons", sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .news-item a .date {
    font-size: 14px;
  }
}
.news-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #1c1c1c;
  transition: 0.2s ease-out;
}
@media (hover: hover) and (pointer: fine) {
  .news-item:hover::after {
    left: 0;
    transition: 0.2s ease-out;
  }
}

.top-about {
  padding: 140px 0;
}
@media screen and (max-width: 767px) {
  .top-about {
    padding: 90px 0 180px;
  }
}

.top-about-main {
  position: relative;
}

.top-about-text {
  margin-top: 20px;
  font-size: 18px;
  line-height: 2;
}
@media screen and (max-width: 767px) {
  .top-about-text {
    width: 80%;
    margin-top: 15px;
    font-size: 15px;
  }
}

.top-about-img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 70%;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .top-about-img {
    width: 80%;
    bottom: -100px;
  }
}
.top-about-img img {
  height: 100%;
  aspect-ratio: 3/2;
}

.top-race {
  position: relative;
}

.top-race-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.3;
  font-family: "TTCommons", sans-serif;
  font-size: 15vw;
  font-weight: 700;
  writing-mode: vertical-lr;
  line-height: 0.4;
}

.top-race-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.top-race-block {
  position: relative;
  overflow: hidden;
  width: calc((100% - 15px) / 2);
}
@media screen and (max-width: 767px) {
  .top-race-block {
    width: 100%;
  }
}
.top-race-block::after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 2;
  transition: opacity 1s ease;
}
@media (hover: hover) and (pointer: fine) {
  .top-race-block:hover::after {
    opacity: 0;
  }
  .top-race-block:hover .top-race-block-title span::before {
    transform: translate(0);
  }
  .top-race-block:hover .top-race-block-img {
    transform: scale(1.05);
    transition: 0.8s ease-out;
  }
}
.top-race-block.ecstore {
  position: relative;
  width: 100%;
}
.top-race-block.ecstore::after {
  content: none;
}
.top-race-block.ecstore .top-race-block-title {
  top: auto;
  bottom: 24px;
  left: 40px;
}
@media screen and (max-width: 767px) {
  .top-race-block.ecstore .top-race-block-title {
    bottom: 16px;
    left: 24px;
    font-size: 30px;
  }
}
.top-race-block.ecstore .top-race-block-img {
  aspect-ratio: 4/1;
}
@media screen and (max-width: 767px) {
  .top-race-block.ecstore .top-race-block-img {
    aspect-ratio: 5/2;
  }
}
.top-race-block.ecstore::before {
  content: "";
  display: block;
  position: absolute;
  right: 40px;
  bottom: 24px;
  z-index: 3;
  width: 40px;
  height: 40px;
  background-image: url(../../common/images/arrow_link.svg);
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .top-race-block.ecstore::before {
    width: 25px;
    height: 25px;
  }
}

.top-race-block-title {
  position: absolute;
  overflow: hidden;
  top: 80px;
  left: 80px;
  z-index: 3;
  font-family: "TTCommons", sans-serif;
  font-size: 5vw;
  font-weight: 700;
  line-height: 1;
}
@media screen and (max-width: 1023px) {
  .top-race-block-title {
    top: 50px;
    left: 50px;
  }
}
@media screen and (max-width: 767px) {
  .top-race-block-title {
    top: 30px;
    left: 20px;
    font-size: 48px;
  }
}
.top-race-block-title span {
  position: relative;
}
.top-race-block-title span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 80%;
  width: 100%;
  transform: translate(-100%);
  z-index: -1;
  background: #111;
  transition: transform 0.3s ease-in-out;
}

.top-race-block-btn {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 3;
  font-family: "TTCommons", sans-serif;
  font-weight: 500;
  font-size: 1vw;
}
@media screen and (max-width: 1023px) {
  .top-race-block-btn {
    right: 20px;
    bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .top-race-block-btn {
    font-size: 20px;
  }
}
.top-race-block-btn .text {
  margin-top: 3px;
}
.top-race-block-btn .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
}
.top-race-block-btn .arrow::after {
  content: "";
  display: block;
  width: 17px;
  height: 14px;
  background-image: url(../../common/images/arrow_right_black.svg);
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
}

.top-race-block-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 15/16;
  object-fit: cover;
  transition: 0.8s ease-out;
}

.top-movie {
  padding: 140px 0;
}
@media screen and (max-width: 767px) {
  .top-movie {
    padding: 60px 0 100px;
  }
}

.top-movie-list {
  position: relative;
}

.top-movie-item {
  margin: 0 6vw;
}
.top-movie-item iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}

.top-logo {
  padding: 80px 0;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .top-logo {
    padding: 40px 0;
  }
}
.top-logo, .top-logo a {
  color: #111;
}

.logo-main {
  display: block;
  width: 250px;
  margin: 0 auto 36px;
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .logo-main:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.logo-list-title {
  margin: 40px 0 8px;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .logo-list-title {
    margin: 30px 0 16px;
  }
}

.logo-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
@media screen and (max-width: 767px) {
  .logo-list {
    justify-content: flex-start;
    gap: 0px;
    padding: 0;
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .logo-list li {
    width: 50%;
  }
}
.logo-list li a {
  position: relative;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .logo-list li a {
    display: flex;
    justify-content: center;
  }
}
.logo-list li a .application {
  display: block;
  position: absolute;
  width: 100%;
  font-size: 0.75em;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .logo-list li a .application {
    bottom: -2px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .logo-list li a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
@media screen and (max-width: 767px) {
  .logo-list li a img {
    max-width: 80%;
    margin-bottom: 16px;
    object-fit: contain;
  }
}
@media screen and (max-width: 767px) {
  .logo-list li a .text {
    display: block;
    max-width: 80%;
    margin: 8px 0 16px;
  }
}

.slide-in-text {
  overflow: hidden;
}

.slide-in-text-inner {
  opacity: 0;
}

.slideAnimeDownUp {
  animation-name: slideTextY100;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}

@keyframes slideTextY100 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes menuLineTop {
  0% {
    top: 0;
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  100% {
    top: 50%;
    transform: translateY(-50%) rotate(-30deg);
  }
}
@keyframes menuLineTopClose {
  0% {
    top: 50%;
    transform: translateY(-50%) rotate(-30deg);
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  100% {
    top: 0;
  }
}
@keyframes menuLineBottom {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 50%;
    transform: translateY(50%);
  }
  100% {
    bottom: 50%;
    transform: translateY(50%) rotate(30deg);
  }
}
@keyframes menuLineBottomClose {
  0% {
    bottom: 50%;
    transform: translateY(50%) rotate(30deg);
  }
  50% {
    bottom: 50%;
    transform: translateY(50%);
  }
  100% {
    bottom: 0;
  }
}
.test-anime-header {
  opacity: 0;
}

.yt {
  margin: 0 6vw;
  aspect-ratio: 16/9;
}
.yt iframe {
  width: 100%;
  height: 100%;
}

.yt_video {
  position: relative;
  aspect-ratio: 16/9;
  height: 100%;
}
@media (hover: hover) and (pointer: fine) {
  .yt_video:hover::before {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.yt_video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.yt_video::before {
  content: "";
  position: absolute;
  width: 68px;
  height: 48px;
  background-image: url(../../common/images/icon_youtube.png);
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.slick-prev,
.slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 1023px) {
  .slick-prev,
.slick-next {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 767px) {
  .slick-prev,
.slick-next {
    top: auto;
    transform: translateY(0);
    bottom: -55px;
    width: 40px;
    height: 40px;
  }
}
.slick-prev::before,
.slick-next::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(../../common/images/arrow_right_white.svg);
  background-position: center 0;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1023px) {
  .slick-prev::before,
.slick-next::before {
    width: 18px;
    height: 18px;
  }
}
@media screen and (max-width: 767px) {
  .slick-prev::before,
.slick-next::before {
    width: 12px;
    height: 12px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .slick-prev:hover,
.slick-next:hover {
    background-color: #1c1c1c;
    transition: 0.3s;
  }
}

.slick-prev {
  left: 15%;
}
.slick-prev::before {
  transform: rotate(180deg);
}
@media screen and (max-width: 767px) {
  .slick-prev {
    left: 35%;
  }
}

.slick-next {
  right: 15%;
}
@media screen and (max-width: 767px) {
  .slick-next {
    right: 35%;
  }
}