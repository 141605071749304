@use "../settings/mixins" as m;
@use "../settings/vars" as v;

.slick-prev,
.slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 80px;
  height: 80px;
  border: 1px solid v.$white;
  border-radius: 50%;
  cursor: pointer;
  @include m.mq(max1023) {
    width: 60px;
    height: 60px;
  }
  @include m.mq(max767) {
    top: auto;
    transform: translateY(0);
    bottom: -55px;
    width: 40px;
    height: 40px;
  }
  &::before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../../common/images/arrow_right_white.svg);
    background-position: center 0;
    background-size: cover;
    background-repeat: no-repeat;
    @include m.mq(max1023) {
      width: 18px;
      height: 18px;
    }
    @include m.mq(max767) {
      width: 12px;
      height: 12px;
    }
  }
  transition: 0.3s;
  @include m.hover() {
    background-color: v.$black02;
    transition: 0.3s;
  }
}
.slick-prev {
  left: 15%;
  &::before {
    transform: rotate(180deg);
  }
  @include m.mq(max767) {
    left: 35%;
  }
}
.slick-next {
  right: 15%;
  @include m.mq(max767) {
    right: 35%;
  }
}
