@use "../settings/mixins" as m;
@use "../settings/vars" as v;

// ACCRタイトル（KV）
.slide-in-text {
  overflow: hidden;
}
.slide-in-text-inner {
  opacity: 0;
}

.slideAnimeDownUp {
  animation-name: slideTextY100;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}

@keyframes slideTextY100 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

// ヘッダーメニュー
@keyframes menuLineTop {
  0% {
    top: 0;
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  100% {
    top: 50%;
    transform: translateY(-50%) rotate(-30deg);
  }
}
@keyframes menuLineTopClose {
  0% {
    top: 50%;
    transform: translateY(-50%) rotate(-30deg);
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  100% {
    top: 0;
  }
}
@keyframes menuLineBottom {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 50%;
    transform: translateY(50%);
  }
  100% {
    bottom: 50%;
    transform: translateY(50%) rotate(30deg);
  }
}
@keyframes menuLineBottomClose {
  0% {
    bottom: 50%;
    transform: translateY(50%) rotate(30deg);
  }
  50% {
    bottom: 50%;
    transform: translateY(50%);
  }
  100% {
    bottom: 0;
  }
}

.test-anime-header {
  opacity: 0;
}
