@use "../settings/mixins" as m;
@use "../settings/vars" as v;

* {
  box-sizing: border-box;
  word-break: break-all;
}
html {
	-webkit-text-size-adjust: none;
}
body {
  font-family: Zen Kaku Gothic New, sans-serif;
  background-color: v.$black;
  color: v.$white;
  line-height: 1.5;
  font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: v.$white;
	text-decoration: none;
}
img {
  display: block;
	max-width: 100%;
  vertical-align: bottom;
}
ol {
  padding-left: 20px;
  list-style-type: decimal;
}
strong {
  font-weight: bold;
}
sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}
sup {
  bottom: 1ex;
}
sub {
  top: .5ex;
}

/* IE表示用のCSS　*/
body.ie {
  font-family: YakuHanJP_Narrow, Meiryo, sans-serif;
}
