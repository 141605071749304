@use "../settings/mixins" as m;
@use "../settings/vars" as v;

.yt {
  margin: 0 6vw;
  aspect-ratio: 16 / 9;
  iframe {
    width: 100%;
    height: 100%;
  }
}
.yt_video {
  position: relative;
  aspect-ratio: 16 / 9;
  height: 100%;
  @include m.hover() {
    &::before {
      opacity: 0.7;
      transition: 0.3s;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  &::before {
    content: '';
    position: absolute;
    width: 68px;
    height: 48px;
    background-image: url(../../common/images/icon_youtube.png);
    background-position: center 0;
    background-size: contain;
    background-repeat: no-repeat;
    top: 50%;
    left: 50%;
    transform : translate(-50%,-50%);
    transition: 0.3s;
  }
}
