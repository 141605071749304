@use "../settings/mixins" as m;
@use "../settings/vars" as v;

// inner
.inner-m,
.inner-l {
	margin: 0 auto;
	box-sizing: content-box;
}
.inner-m {
	max-width: 1024px;
}
.inner-l {
	max-width: 1600px;
	width: 90%;
}

// text-align
.center {
	text-align: center;
}

// br
.br-pc {
	@include m.mq(max767) {
		display: none;
	}
}

// header
.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	padding: 40px;
	@include m.mq(max767) {
		padding: 12px;
	}
}
.header-inner {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}
.header-logo {
	@include m.mq(max767) {
		width: 80px;
		height: 36px;
	}
	transition: 0.3s;
  @include m.hover() {
    opacity: 0.7;
    transition: 0.3s;
  }
	img {
		height: 100%;
	}
}
.header-right {
	display: flex;
	align-items: center;
}
.header-right-icons {
	display: flex;
	gap: 24px;
	a {
		transition: 0.3s;
		@include m.hover() {
			opacity: 0.7;
			transition: 0.3s;
		}
		img {
			width: auto;
			height: 20px;
		}
	}
}
.header-menu-btn {
	position: relative;
	width: 40px;
	height: 20px;
	margin-left: 40px;
	cursor: pointer;
	transition: 0.3s;
  @include m.hover() {
    opacity: 0.7;
    transition: 0.3s;
  }
	span {
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: v.$white;
		&:nth-child(1),
		&:nth-child(3) {
			animation-duration: 0.3s;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
		}
		&:nth-child(1) {
			top: 0;
			animation-name: menuLineTopClose;
		}
		&:nth-child(2) {
			top: 50%;
			transform: translateY(-50%);
			opacity: 1;
			transition: 0.3s cubic-bezier(.83,0,.17,1);
		}
		&:nth-child(3) {
			bottom: 0;
			animation-name: menuLineBottomClose;
		}
	}
	&.active {
		span {
			&:nth-child(1) {
				animation-name: menuLineTop;
			}
			&:nth-child(2) {
				opacity: 0;
				transition: 0.3s cubic-bezier(.83,0,.17,1);
			}
			&:nth-child(3) {
				animation-name: menuLineBottom;
			}
		}
	}
}
.header-nav {
	overflow: scroll;
	display: grid;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	padding: 130px 10%;
	background-color: v.$black;
	transform: translateY(-100%);
	transition: 0.5s cubic-bezier(.83, 0, .17, 1);
	@include m.mq(max767) {
		align-items: flex-start;
		padding: 70px 10%;
	}
	&.show {
		transform: translateY(0);
    transition: 0.5s cubic-bezier(.83, 0, .17, 1);
	}
	&::-webkit-scrollbar {
		display: none;
	}
}
.header-nav-title {
	margin-bottom: 24px;
	@include m.avant;
	font-size: 36px;
	@include m.mq(max767) {
		margin-bottom: 12px;
		font-size: 24px;
	}
}
.header-nav-list {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	padding: 24px 0;
	border-top: 1px solid v.$gray03;
	@include m.mq(max767) {
		padding: 12px 0;
	}
	&.main {
		.header-nav-item {
			width: calc((100% - 32px)/3);
			@include m.mq(max767) {
				width: 100%;
			}
		}
	}
	&.sub {
		.header-nav-item {
			width: 100%;
		}
	}
}
.header-nav-item {
	a {
		display: inline-flex;
		flex-direction: column;
		@include m.mq(max767) {
			flex-direction: row;
			align-items: center;
			gap: 10px;
		}
		.en {
			@include m.avant;
			font-size: 24px;
			letter-spacing: 0.05em;
			@include m.mq(max767) {
				font-size: 16px;
			}
			&.external-link {
				display: flex;
				align-items: center;
				gap: 10px;
				@include m.mq(max767) {
					gap: 7px;
				}
				&::after {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					background-image: url(../../common/images/icon_external-link.svg);
					background-position: center 0;
					background-size: contain;
					background-repeat: no-repeat;
					@include m.mq(max767) {
						width: 15px;
						height: 15px;
					}
				}
			}
		}
		.jp {
			color: v.$gray02;
			@include m.mq(max767) {
				font-size: 12px;
			}
		}
		transition: 0.3s;
		@include m.hover() {
			opacity: 0.7;
			transition: 0.3s;
		}
	}
}
.test {
	opacity: 0;
}

// footer
.footer {
	padding: 50px 0;
	@include m.mq(max767) {
		padding: 40px 0;
	}
}
.footer-inner {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	gap: 30px;
}
.footer-sns {
	display: flex;
	gap: 30px;
	margin-bottom: 30px;
	@include m.ttc;
	font-size: 20px;
	font-weight: 500;
	@include m.mq(max767) {
		flex-direction: column;
		gap: 10px;
	}
}
.footer-sns-list {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	@include m.mq(max767) {
		gap: 0 24px;
	}
	a {
		display: flex;
		align-items: center;
		gap: 8px;
		&::after {
			content: '';
			display: block;
			width: 10px;
			height: 12px;
			background-image: url(../../common/images/arrow_link.svg);
			background-position: center 0;
			background-size: contain;
			background-repeat: no-repeat;
		}
		transition: 0.3s;
		@include m.hover() {
			opacity: 0.7;
			transition: 0.3s;
		}
	}
}
.footer-copyright {
	@include m.ttc;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
}

// section
.section-padding {
	padding: 140px 0;
}

